import KinhubLogo from "./KinhubLogo";
import clsxm from "@lib/clsxm";
import Image from "next/image";

type TestimonialTypes = {
  image?: string;
  color?: string;
  text?: boolean;
};

export default function Testimonial({ image, color, text = true }: TestimonialTypes) {
  return (
    <div
      className={clsxm(
        "flex h-full w-full items-center justify-center overflow-hidden bg-brand-1",
        color && `bg-${color}`,
        image && "relative z-[0] bg-black",
      )}
    >
      {image && (
        <Image
          src={image}
          className={clsxm("z-0 scale-125 opacity-100", text && "opacity-70")}
          layout="fill"
          objectFit="cover"
          alt="cover-image"
        />
      )}

      <div className="absolute z-10 flex max-w-xl flex-col px-6">
        <KinhubLogo className={clsxm("h-[80px] w-[150px] self-center fill-white", !text && "hidden")} />
        <h1
          className={clsxm(
            "mb-3 text-center font-heading text-4xl font-semibold text-white xl:text-5xl",
            !text && "hidden",
          )}
        >
          Personalised support for life&#39;s key journeys
        </h1>
      </div>
    </div>
  );
}
