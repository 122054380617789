import clsx from "clsx";
import clsxm from "@lib/clsxm";
import React from "react";
import { ReactNode } from "react";

type ModalTypes = {
  hidden?: boolean;
  background?: boolean;
  children?: ReactNode;
  className?: string;
  onBgClick?: () => void;
};

function Modal({ hidden, children, className, background = true, onBgClick }: ModalTypes) {
  return (
    <div
      className={clsxm(
        "fixed z-[9999]",
        background ? "left-0 top-0 h-screen w-full items-center justify-center" : "",
        hidden ? "hidden" : "flex",
      )}
    >
      <div
        onClick={onBgClick}
        className={clsx(
          "fixed z-[9998] h-screen w-full items-center justify-center bg-black bg-opacity-40",
          hidden ? "hidden" : "flex",
          background ? "flex" : "hidden",
        )}
      ></div>
      <div
        className={clsxm(
          "z-[9999] flex h-full max-h-[300px] w-[85%] max-w-[400px] flex-col items-center justify-center rounded-md bg-white p-4 md:w-full",
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
}

export default Modal;
