import { ReactNode } from "react";
import { useEffect, useRef } from "react";
import clsxm from "@lib/clsxm";

export default function Pane({
  left,
  right,
}: {
  left: ReactNode;
  right: ReactNode;
  overflowing?: boolean;
}) {
  const leftPaneRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (leftPaneRef.current) leftPaneRef.current.scrollTo({ top: 0, behavior: "auto" });
  }, [left]);

  return (
    <div className="w-full flex">
      <div ref={leftPaneRef} className={clsxm("lg:w-1/2 lg:basis-1/2 hidden lg:flex")}>
        <div className={clsxm("h-full w-full")}>{left}</div>
      </div>

      <div
        className={clsxm(
          "lg:w-1/2 lg:basis-1/2 flex h-full w-full basis-full flex-col items-center justify-start px-4 pt-12 sm:overflow-y-auto sm:px-6 lg:w-1/2 lg:basis-1/2 lg:justify-center",
        )}
      >
        <div className={clsxm("h-full w-full max-w-md")}>{right}</div>
      </div>
    </div>
  );
}
